
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    width="80%"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'150px'"
        inline
      >
        <el-row>
          <el-col>
            <el-form-item label="图标名称" prop="iconName">
              <el-input
                v-model="formModel.iconName"
                placeholder="请输入图标名称"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="图标路由" prop="iconRoute">
              <el-input
                v-model="formModel.iconRoute"
                placeholder="请输入图标路由"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="图标的子页面路由" prop="iconSubRoute">
              <el-input
                v-model="formModel.iconSubRoute"
                placeholder="请输入图标的子页面路由"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="图标url" prop="iconUrl">
              <el-input
                v-model="formModel.iconUrl"
                placeholder="请输入图标url"
                style="width: 300px"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="参数" prop="iconParam">
              <el-input
                v-model="formModel.iconParam"
                placeholder="请输入参数"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="图标图片" prop="iconPic">
              <el-upload
                class="avatar-uploader"
                name="photoFile"
                :action="uploadUrl"
                :show-file-list="false"
                :headers="headers"
                :data="uploadData"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="iconPic" :src="iconPic" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="通用功能展示的图片" prop="iconCommonPic">
              <el-upload
                class="avatar-uploader"
                name="photoFile"
                :action="uploadUrl"
                :show-file-list="false"
                :headers="headers"
                :data="uploadData"
                :on-success="handleAvatarSuccessIconCommonPic"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="iconCommonPic" :src="iconCommonPic" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <!-- <el-form-item label="图标类型" prop="iconTypeId">
          <el-input
            v-model="formModel.iconTypeId"
            placeholder="请输入图标类型"
            style="width: 300px"
          ></el-input>
        </el-form-item> -->
            <el-form-item label="图标类型" prop="iconTypeId">
              <el-select v-model="formModel.iconTypeId" style="width: 80%">
                <el-option
                  v-for="(item, index) in iconTypeList"
                  :label="item.typeName"
                  :value="item.id"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="备注信息" prop="remark">
              <el-input
                v-model="formModel.remark"
                placeholder="请输入备注信息"
                style="width: 300px"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否启用" prop="useEnable">
              <el-switch
                v-model="formModel.useEnable"
                active-color="#13ce66"
                active-text="是"
                inactive-color="#ff4949"
                inactive-text="否"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import iconInfoApi from "@/api/base/iconInfo";
import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      iconTypeList: [],
      formModel: {},
      ruleValidate: {
        iconName: [
          { required: true, message: "图标名称不能为空", trigger: "blur" },
        ],
        iconRoute: [
          { required: true, message: "图标路由不能为空", trigger: "blur" },
        ],
        iconPic: [
          { required: true, message: "图标图片不能为空", trigger: "blur" },
        ],
        iconTypeId: [
          { required: true, message: "图标类型id不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      //上传地址
      uploadUrl: Constant.serverUrl + "/uploadPicture",
      uploadData: {
        subFolder: "personInfo",
      },
      iconPic: "",
      iconCommonPic: "",
      headers: {
        Authorization: getToken(),
      },
    };
  },
  created() {
    // this.loadTree();

    iconInfoApi.getIconTypeList().then((response) => {
      var jsonData = response.data;
      this.iconTypeList = jsonData.data;
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return iconInfoApi.add(self.formModel);
            } else {
              return iconInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      var self = this;
      self.formModel.iconPic = res.data;
      self.iconPic =
        res.data + "?x-oss-process=image/resize,m_lfit,h_40,w_40";
    },
    handleAvatarSuccessIconCommonPic(res, file) {
      var self = this;
      self.formModel.iconCommonPic = res.data;
      self.iconCommonPic =
        res.data + "?x-oss-process=image/resize,m_lfit,h_40,w_40";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG) && isLt2M;
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return iconInfoApi.create();
      } else {
        return iconInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          let iconPic = self.formModel.iconPic;
          if (iconPic != null) {
            self.iconPic =
              iconPic + "?x-oss-process=image/resize,m_lfit,h_40,w_40";
          }

          let iconCommonPic = self.formModel.iconCommonPic;
          if (iconCommonPic != null) {
            self.iconCommonPic =
              iconCommonPic + "?x-oss-process=image/resize,m_lfit,h_40,w_40";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>