<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/iconInfo">图标管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="图标名称" prop="iconName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.iconName"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注信息" prop="remark">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.remark"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="iconName"
        label="图标名称"
        width="130"
      ></el-table-column>
      <el-table-column
        prop="iconRoute"
        label="图标路由"
        show-overflow-tooltip
        width="220"
      ></el-table-column>
      <el-table-column
        prop="iconSubRoute"
        label="子页面路由"
        width="200"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="iconUrl"
        label="图标url"
        show-overflow-tooltip
        width="150"
      ></el-table-column>
      <el-table-column
        prop="iconParam"
        label="参数"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="useEnable"
        label="是否启用"
        width="120"
      >
      <template slot-scope="{ row }">
          <el-switch
            v-model="row.useEnable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateEnabled(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="iconPic"
        label="图标图片"
        width="80"
      >
      <template slot-scope="{ row }">
          <a :href="row.iconPic" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.iconPic +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="iconCommonPic"
        label="通用功能展示的图片"
        width="150"
      >
      <template slot-scope="{ row }">
          <a :href="row.iconCommonPic" target="_blank">
            <el-avatar
              :size="48"
              shape="circle"
              :src="
                row.iconCommonPic +
                '?x-oss-process=image/resize,m_fill,w_64,h_64'
              "
              :key="row.id"
            ></el-avatar>
          </a>
        </template>
      </el-table-column>
      <el-table-column
        prop="iconTypeName"
        label="图标类型"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注信息"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="150">
        <template slot-scope="{ row }">
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <iconInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></iconInfo-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import IconInfoDetail from "./iconInfo-detail";
import iconInfoApi from "@/api/base/iconInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseIconInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        iconName: "",
        iconRoute: "",
        iconSubRoute: "",
        iconUrl: "",
        iconParam: "",
        useEnable: "",
        iconPic: "",
        iconCommonPic: "",
        iconTypeId: "",
        delFlag: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        remark: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("iconName", self.queryModel.iconName);
      formData.append("iconRoute", self.queryModel.iconRoute);
      formData.append("iconSubRoute", self.queryModel.iconSubRoute);
      formData.append("iconUrl", self.queryModel.iconUrl);
      formData.append("iconParam", self.queryModel.iconParam);
      formData.append("useEnable", self.queryModel.useEnable);
      formData.append("iconPic", self.queryModel.iconPic);
      formData.append("iconCommonPic", self.queryModel.iconCommonPic);
      formData.append("iconTypeId", self.queryModel.iconTypeId);
      formData.append("delFlag", self.queryModel.delFlag);
      formData.append("createBy", self.queryModel.createBy);
      formData.append("createTime", self.queryModel.createTime);
      formData.append("updateBy", self.queryModel.updateBy);
      formData.append("updateTime", self.queryModel.updateTime);
      formData.append("remark", self.queryModel.remark);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      iconInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          iconInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        iconInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    updateEnabled(row) {
      iconInfoApi.update(row);
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "iconInfo-detail": IconInfoDetail,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>